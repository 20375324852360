<template>
  <div>
    <span> Please select attendees: </span>
    <v-divider class="mt-1"></v-divider>
    <v-data-table
      class="mt-2"
      v-model="selectedItems"
      show-select
      item-key="name"
      :headers="headers"
      :items="tableData"
      hide-default-footer
      disable-pagination
      disable-sort
      ref="table"
    >
      <!-- Custom footer with a "Save" button -->
      <template v-slot:footer>
        <v-row justify="end">
          <v-col cols="auto">
            <base-tooltip-button @click="saveMeetingRollCall">
              Save
              <template v-slot:toolTip>
                {{ buttonSaveTooltip }}
              </template>
            </base-tooltip-button>
          </v-col>
        </v-row>
      </template>

      <template v-slot:header.vote="{ header }">
        <v-row>
          <v-simple-checkbox
            class="ml-3"
            v-model="selectAllVotes"
          ></v-simple-checkbox>
          <span class="mt-1">{{ header.text }}</span>
        </v-row>
      </template>

      <template v-slot:item.status="{ item }">
        <v-select
          style="width: 150px"
          dense
          hide-details
          item-text="name"
          item-value="id"
          v-model="item.status"
          :items="statusItems"
        >
        </v-select>
      </template>

      <template v-slot:item.vote="{ item }">
        <v-simple-checkbox v-model="item.vote"></v-simple-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// vuex store
import { createNamespacedHelpers } from "vuex";
//import { snackbarableMixin } from "@/mixins/shared/snackbarable/snackbarableMixin";

const { mapActions } = createNamespacedHelpers("agenda");
export default {
  name: "AgendaRollCallCard",
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  props: {
    record: undefined
  },
  data() {
    return {
      meetingRollCall: undefined,
      headers: [
        {
          text: "Member",
          align: "start",
          value: "name"
        },
        { text: "Status", value: "status" },
        { text: "Vote", value: "vote" }
      ],
      tableData: [],
      selectedItems: undefined,
      selectedStatus: undefined,
      selectAllVotes: false
    };
  },

  computed: {
    /**
     * Get the ID of the current record or a default value of -1 if not available.
     * @returns {number}
     */
    recordId() {
      return this.record?.id ?? -1;
    },

    /**
     * Get Statuses for roll call
     * @return {*|*[]}
     */
    statusItems() {
      return this.meetingRollCall.rollCallStatus ?? [];
    },

    /**
     * Save Command Tooltip
     * @return {string}
     */
    buttonSaveTooltip() {
      return "Save roll call for current meeting";
    }
  },

  methods: {
    /**
     * Map Vuex actions to component methods.
     */
    ...mapActions({
      getRollCall: "getRollCall",
      saveRollCall: "saveRollCall"
    }),

    /**
     * Generate Row Data for the Roll call Table
     */
    createTableData() {
      console.log("RollCallModel", this.meetingRollCall);
      this.tableData = [];
      if (this.meetingRollCall.rollCall.length) {
        this.meetingRollCall.rollCall.forEach(rollCall => {
          this.tableData.push({
            name: rollCall.actor, // when actor object is corrected, should be rollCall.actor.name
            vote: rollCall.vote,
            status: rollCall.rollCallStatus.id
          });
        });
      } else {
        this.meetingRollCall.actor.forEach(actor => {
          this.tableData.push({
            name: actor, //when actor object is corrected, should be rollCall.actor.name
            vote: false,
            status: this.meetingRollCall.rollCallStatus[0].id
          });
        });
      }

      console.log("TableData", this.tableData);
    },

    /**
     * On Save Roll Call Action
     * @return {Promise<void>}
     */
    async saveMeetingRollCall() {
      console.log("Actor", this.meetingRollCall.actor);
      const rollCall = [];
      if (this.selectedItems) {
        this.selectedItems.forEach(item => {
          rollCall.push({
            RecordId: -1,
            Actor: null, // once Actor Model is fixed, it should be this.meetingRollCall.actor.find(actor => actor.name === item.name)
            RollCallStatus: this.meetingRollCall.rollCallStatus.find(
              status => status.id === item.status
            ),
            Vote: item.vote,
            RollCallMemberType: 0
          });
        });
      }
      const payload = {
        id: this.recordId,
        rollCall: rollCall
      };
      console.log("payload", payload);

      await this.saveRollCall(payload);
    },

    /**
     * Get Roll Call Data for Meeting
     * @return {Promise<{rollCall:Object[], actor:Object[], rollCallStatus:Object[] }>}
     */
    async getMeetingRollCall() {
      return await this.getRollCall(this.recordId);
    }
  },

  async mounted() {
    this.meetingRollCall = await this.getMeetingRollCall();
  },

  watch: {
    /**
     * Watch when Roll call Data Change
     */
    meetingRollCall() {
      this.createTableData();
    },

    /**
     * Watch on record Change
     * @return {Promise<void>}
     */
    async record() {
      this.meetingRollCall = await this.getMeetingRollCall();
    },

    /**
     * watch selectAllVotes to toggle individual vote
     * @param newValue
     */
    selectAllVotes(newValue) {
      this.tableData.forEach(item => {
        item.vote = newValue;
      });
    },

    /**
     * watch TableData handle votes
     */
    tableData: {
      deep: true,
      handler(newTableData) {
        // Check if all items in tableData have the 'vote' property set to true
        this.selectAllVotes = newTableData.every(item => item.vote);
      }
    }
  }
};
</script>

<style scoped></style>
